/* 修改滚动条宽度 */
::-webkit-scrollbar {
  width: 10px;
}

/* 修改滚动条颜色 */
::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

/* 修改滚动条背景颜色 */
::-webkit-scrollbar-track {
  background-color: #eee;
}
/* 修改滚动条 thumb 的圆角 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* 修改滚动条 track 的圆角 */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
@keyframes ldio-sca6s4si7ee-r {
  0%, 100% { animation-timing-function: cubic-bezier(0.2 0 0.8 0.8) }
  50% { animation-timing-function: cubic-bezier(0.2 0.2 0.8 1) }
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
@keyframes ldio-sca6s4si7ee-s {
  0%, 100% { animation-timing-function: cubic-bezier(0.2 0 0.8 0.8) }
  50% { animation-timing-function: cubic-bezier(0.2 0.2 0.8 1) }
  0% { transform: translate(-30px,-30px) scale(0) }
  50% { transform: translate(-30px,-30px) scale(1) }
  100% { transform: translate(-30px,-30px) scale(0) }
}
.ldio-sca6s4si7ee > div { transform: translate(0px,-15px) }
.ldio-sca6s4si7ee > div > div {
  animation: ldio-sca6s4si7ee-r 1s linear infinite;
  transform-origin: 100px 100px;
}
.ldio-sca6s4si7ee > div > div > div {
  position: absolute;
  transform: translate(100px, 82px);
}
.ldio-sca6s4si7ee > div > div > div > div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fe718d;
  animation: ldio-sca6s4si7ee-s 1s linear infinite;
}
.ldio-sca6s4si7ee > div > div:last-child {
  animation-delay: -0.5s;
}
.ldio-sca6s4si7ee > div > div:last-child > div > div {
  animation-delay: -0.5s;
  background: #46dff0;
}
.loadingio-spinner-interwind-o1710737tqc {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  /* background: #ffffff; */
}
.ldio-sca6s4si7ee {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-sca6s4si7ee div { box-sizing: content-box; }